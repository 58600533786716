import { useEffect } from 'react';
import { AuthProps, TelegramUser } from 'types/auth';
import './style.css';
declare global {
  interface Window {
    onTelegramAuth: (user: TelegramUser) => void;
  }
}

type Props = {
  authMethod: (data: AuthProps) => void;
};
const TelegramLogin = ({ authMethod }: Props) => {
  useEffect(() => {
    window.onTelegramAuth = function (user: TelegramUser) {
      const data = {
        provider: 'telegram',
        provider_user_id: user.id,
        name: user.first_name,
        provider_data: user,
        photoProfile: user.photo_url,
      };
      authMethod(data);
    };

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.setAttribute('data-telegram-login', 'miningoptionsbot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    document.getElementById('telegram-login')?.appendChild(script);

    return () => {
      document.getElementById('telegram-login')?.removeChild(script);
    };
  }, [authMethod]);

  return (
    <div className="btn-provider telegram-login content-telegram">
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/1200px-Telegram_logo.svg.png"
        alt="google"
      />
      <span>Continue with Telegram</span>
      <div id="telegram-login"></div>
    </div>
  );
};

export default TelegramLogin;
