import LogoWhite from '@assets/logos/white.svg';
import './style.css';
import Buttom from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReactComponent as BinanceIcon } from '@assets/icons/binance.svg';

const Index = () => {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const referCode = queryParams.get('refercode');

    if (referCode) {
      localStorage.setItem('refercode', referCode);
      console.log('Refercode saved:', referCode);
    } else {
      localStorage.removeItem('refercode');
    }
  }, [location]);

  const FAQItem = ({
    question,
    answer,
  }: {
    question: string;
    answer: string;
  }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="faq-item">
        <button className="faq-question" onClick={() => setIsOpen(!isOpen)}>
          {question}
          <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9662;</span>
        </button>
        <div className={`faq-answer-container ${isOpen ? 'open' : ''}`}>
          <div className="faq-answer">{answer}</div>
        </div>
      </div>
    );
  };

  const faqData = [
    {
      question: 'Can I participate in all staking contracts?',
      answer:
        'Yes, you can participate in any staking contract as long as you meet the requirements.',
    },
    {
      question: 'What are the risks of staking?',
      answer:
        'Staking involves risks, including the possibility of loss due to price fluctuations.',
    },
    {
      question: 'How do I withdraw my rewards?',
      answer:
        "You can withdraw your rewards through the staking dashboard in the 'Withdraw' section.",
    },
    {
      question: 'Is there a fee for staking?',
      answer: 'There may be minimal fees depending on the blockchain used.',
    },
  ];

  return (
    <div className="launch-container">
      <nav>
        <div className="content-nav">
          <div>
            <img src={LogoWhite} alt="MiningOptions" className="logoLaunch" />
          </div>
          <div>
            <ul>
              <li>
                <a href="#">DMO Token</a>
              </li>
              <li>
                <a href="#">White Paper</a>
              </li>
              <li>
                <a href="#">FAQ</a>
              </li>
              <li>
                <a href="#">Contact us</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="social-network">
          <i className="fab fa-telegram-plane"></i>
          <i className="fab fa-twitter"></i>
          <i className="fab fa-instagram-square"></i>
          <i className="fab fa-youtube"></i>
          <BinanceIcon />
        </div>
      </nav>
      <br />
      {/** <p>The most innovative and profitable staking options in a one app</p>*/}
      <div id="hero">
        <h4>The most innovative and profitable staking options in a one app</h4>
      </div>
      <div className="launch-btns">
        <div>
          <Buttom
            size="lg"
            label="Desktop Launch"
            onClick={() => {
              navigate('/sing-in');
            }}
          />
        </div>
        <div>
          <Buttom
            size="lg"
            label="Telegram Launch"
            onClick={() => {
              window.location.href = 'https://t.me/Miningoptionsbot';
            }}
          />
        </div>
      </div>
      <section>
        <div id="usability">
          <div className="item-usability left">
            <div className="item">
              <h3>EASY TO USE</h3>
            </div>
            <div className="item">
              <h3>USDT REDWARDS</h3>
            </div>
          </div>
          <div id="image-phone">
            <img
              src="https://static.vecteezy.com/system/resources/thumbnails/027/663/854/small_2x/mockup-iphone-10-10s-11-11pro-and-new-iphone-12-12pro-12-mini-mock-up-screen-iphone-vector-illustration-generative-ai-png.png"
              alt=""
            />
          </div>
          <div className="item-usability right">
            <div className="item">
              <h3>TELEGRAM COMPATIBILITY</h3>
            </div>
            <div className="item">
              <h3>NO PERSONAL DATA</h3>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="content">
        <h1>MINING OPTIONS</h1>
        <div className="card-home">
          <p>
            Mining Options is a technological development of investments based
            on the integration of participation pools in our PoS and DPoS
            network. M-O offers a completely decentralized and secure service
            through a comfortable interface with values ​​expressed in USDT
            where you will not have to worry about high chain exchange fees to
            execute your operations or the constant price fluctuations of our
            main tokens and integrated nodes, thus delivering daily and stable
            rewards to all users. All this is possible thanks to a long-term
            focus of M-O and a participation contribution agreement with the
            associated chains to achieve greater scalability and security.
          </p>
        </div>
      </section>
      <br />
      <section id="core-features" className="content">
        <center>
          <h1>CORE FEATURES</h1>
        </center>
        <br />

        <div className="flex-row">
          <div className="card-home">
            <h2>Permanent availability of your funds</h2>
            <p>
              At mining options we strive to optimize a rewards service
              according to the needs of our users, regardless of the selected
              blocking time you can withdraw or claim your funds in full and at
              any time, giving up your share of rewards obtained until the time
              of cancellation.
            </p>
          </div>
          <div className="card-home">
            <h2>Fast deposits and withdrawals process</h2>
            <p>
              With the integration of Binance Smart Chain we can guarantee
              automatic processing of deposits and withdrawals within our
              platform with the lowest possible fees.
            </p>
          </div>
        </div>
        <br />
        <br />

        <div className="content">
          <div className="flex-row">
            <div className="card-home">
              <p className="text-center">
                Complete simple tasks on social networks and increase your
                rewards
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Get $DMO tokens for free during our exclusive airdrop event
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Earn extra USDT with entertaining mini games with immediate
                liquidity
              </p>
            </div>
          </div>
        </div>

        <br />

        <div className="content">
          <div className="flex-row">
            <div className="card-home">
              <p className="text-center">
                Personalized support with a maximum response time of 24 hours
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Linking profiles for greater accessibility at all times
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Earn up to 8% of your direct referrals stake earnings
              </p>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br /> <br />
      <br />
      <section id="roadmap" className="content">
        <center>
          <h1>ROADMAP</h1>
        </center>
        <br />

        <div className="flex-row">
          <div className="card-home">
            <h2 className="text-center">PHASE 1 - 2024</h2>
            <ul className="active">
              <li>Telegram MiniApp integration</li>
              <li>Binance Smart Chain integration</li>
              <li>Rewarded Community Taks</li>
              <li>Telegram MiniApp integration</li>
              <li>Launch Stake</li>
              <li>Token Airdrop</li>
            </ul>
          </div>
          <div className="card-home">
            <h2 className="text-center">PHASE 2 - Q1/Q2 2025</h2>
            <ul>
              <li>Airdrop Allocation</li>
              <li>Internal $DMO transfers</li>
              <li>Pre-Market Trading $DMO</li>
              <li>$DMO LaunchPool</li>
            </ul>
          </div>
        </div>
        <br />
        <br />

        <div className="flex-row">
          <div className="card-home">
            <h2 className="text-center">PHASE 3 - Q3/Q4 2025</h2>
            <ul>
              <li>$DMO TGE</li>
              <li>Exchange Listing</li>
              <li>$DMO Stake</li>
              <li>Telegram MiniApp integration</li>
              <li>New Interface</li>
            </ul>
          </div>
          <div className="card-home">
            <h2 className="text-center">PHASE 4 - 2026</h2>
            <ul>
              <li>Games rewarded in usdt</li>
              <li>Web 3 integration</li>
              <li>Margin Trade Demo</li>
              <li>Launch Margin DEX</li>
            </ul>
          </div>
        </div>
      </section>
      <br />
      <br />
      <section className="content">
        <div className="flex-row aling-items-center">
          <div className="laptop-content-img">
            <img src={require('./assets/images/stake.png')} alt="" />
          </div>
          <div>
            <div className="card-home">
              <h1>
                Access from any device and track your stakes in real time by
                linking your Google or Apple accounts with Telegram
              </h1>
            </div>
            <br />
            <br />

            <div id="content-access">
              <div>
                <img src={require('./assets/images/google.png')} alt="google" />
                <img src={require('./assets/images/apple.png')} alt="apple" />
                <img
                  src={require('./assets/images/telegram.png')}
                  alt="telegram"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br /> <br />
      <section id="faq" className="content">
        <center>
          <h1>FAQ</h1>
        </center>
        <br />
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
        <br />
      </section>
      <br />
      <br />
      <section className="content" id="start-you-crypto">
        <br />
        <div className="card-home">
          <div className="flex-row">
            <div className="item-start-your">
              <h2 className="text-center">
                Start your crypto journey today and make profits from day one
              </h2>
              <Buttom
                size="lg"
                label="Register Now"
                onClick={() => {
                  navigate('/sing-in');
                }}
              />
            </div>
            <div className="item-start-your">
              <div className="flex-row justify-content-center">
                <img
                  style={{ width: '80%' }}
                  src="https://static.vecteezy.com/system/resources/thumbnails/027/663/854/small_2x/mockup-iphone-10-10s-11-11pro-and-new-iphone-12-12pro-12-mini-mock-up-screen-iphone-vector-illustration-generative-ai-png.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </section>
      <br />
      <br />
      <section className="content">
        <div className="flex-row">
          <div className="card-home" id="content-footer-logo">
            <img src={LogoWhite} alt="MiningOptions" className="logofooter" />
            <p>
              The most innovative and profitable staking options in a one app
            </p>
            <div className="social-network-footer">
              <i className="fab fa-telegram-plane"></i>
              <i className="fab fa-twitter"></i>
              <i className="fab fa-instagram-square"></i>
              <i className="fab fa-youtube"></i>
              <BinanceIcon />
            </div>
          </div>
          <div id="footer-btns">
            <div>
              <div>DMO Token</div>
              <div>White Paper</div>
              <div>How to Stake</div>
              <div>Contact Us</div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
    </div>
  );
};

export default Index;
