import Layout from '@components/layout';
import './style.css';
import { useEffect, useRef, useState } from 'react';
import Buttom from '@components/buttons';

function App() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [option, setOption] = useState<
    | 'tasks'
    | 'stake'
    | 'deposit'
    | 'withdraw'
    | 'referrals'
    | 'airdrop'
    | 'account'
    | 'other'
    | 'none'
  >('none');
  const [currentOption, setCurrentOption] = useState<string>(option);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  if (option !== currentOption) {
    setOpenIndex(null);
    setCurrentOption(option);
  }

  const faqData: {
    tasks: { question: string; answer: string }[];
    stake: { question: string; answer: string }[];
    deposit: { question: string; answer: string }[];
    withdraw: { question: string; answer: string }[];
    referrals: { question: string; answer: string }[];
    airdrop: { question: string; answer: string }[];
    account: { question: string; answer: string }[];
    other: { question: string; answer: string }[];
  } = {
    tasks: [
      {
        question: "Why can't I access the tasks?",
        answer:
          'Only accounts that have a linked Telegram profile can access the subscription tasks.',
      },
      {
        question: 'Why is my task not verified?',
        answer: 'Subscription tasks have a review time of up to 48 hours.',
      },
      {
        question: 'How long does the increase in referral rewards last?',
        answer:
          'It lasts 30 days, you will receive a notification indicating when it is about to end.',
      },
      {
        question: 'Where can I track my rewards?',
        answer:
          'Once the increase is activated, it is applied automatically and you can track it in the “Referrals” section.',
      },
      {
        question: 'I sent a link and have not received a response',
        answer:
          'The link review time depends on the flow of users and there is no time limit, they will be approved in order of arrival.',
      },
      {
        question: 'Where can I see the increase in my stake rewards?',
        answer:
          'You will be able to see the increase in your benefits reflected in the history of completed stakes from “Stake Summary.”',
      },
    ],
    stake: [
      {
        question: 'Can I cancel my stake once it has started?',
        answer:
          "Yes, I can do so from the 'Stake Summary' and at any time you will see the refund process in the deposit section in 'Wallet'.",
      },
      {
        question: 'How can I increase my trading limit?',
        answer:
          'The trading limit has no pre-setting, it is set based on the first amount entered into the selected contract and resets after 30 days.',
      },
      {
        question: 'Do contracts longer than 30 days have compound interest?',
        answer:
          '90 and 180 contracts reinvest every 30 days the entire amount obtained until their completion.',
      },
      {
        question: 'Does the trading limit reset if I cancel a stake?',
        answer:
          'If you have not received rewards on that specific contract, you can cancel your stake and you will not have any operation limit.',
      },
      {
        question: 'Is the trading limit set on all contracts at once?',
        answer:
          'The operation limit is independent of each contract, 30, 90, and 180 contracts have a 30-day trading limit just like the others.',
      },
      {
        question: 'Can I use all contracts at once?',
        answer: 'Yes, you can participate in all contracts simultaneously.',
      },
    ],
    deposit: [
      {
        question: 'How long does it take for my deposit to be reflected?',
        answer:
          "USDT deposits work within Binance's BEP 20 network and are reflected in your balance according to the network delay, which is only a few minutes.",
      },
      {
        question: 'Can I deposit another token into the wallet?',
        answer:
          "Contact support directly by pressing 'Other' to help with your case.",
      },
      {
        question: 'Can deposit wallets change?',
        answer:
          'Wallets are unique for each user and will not change without prior notice. It is also recommended to always check.',
      },
    ],
    withdraw: [
      {
        question: 'How long does it take to process my withdrawal?',
        answer:
          'Withdrawals have a semi-automatic verification process and are sent within 8 hours.',
      },
      {
        question: 'Do I have a maximum withdrawal limit per day?',
        answer:
          'There is no restriction on withdrawing your funds, you can do so at any time.',
      },
      {
        question: 'Where do I track my withdrawal?',
        answer:
          "You will see the status of 'Processing' when you request it and 'Completed' when it is in your wallet.",
      },
      {
        question: 'My withdrawal exceeded the maximum waiting time',
        answer:
          "Contact support directly by clicking 'Other' and your case will be attended to with priority.",
      },
    ],
    referrals: [
      {
        question: "How do I get my referrals' rewards?",
        answer:
          "In the 'Wallet' section, press 'Transfer', select 'Referrals' as the source point and send directly to 'Stake' or 'Wallet'.",
      },
      {
        question:
          "How long does it take for my referrals' staking reward to reflect?",
        answer:
          'Will your referral see their reward immediately after completing a Stake?',
      },
      {
        question: 'How many referral levels are there?',
        answer:
          'There is only one referral level, you can only get rewards from your direct referrals.',
      },
    ],
    airdrop: [
      {
        question: 'How many tickets can I get to accumulate points?',
        answer:
          'You can get 3 tickets immediately when you create your account and you can receive one free ticket every day for 30 days.',
      },
      {
        question: 'How to get more points?',
        answer:
          "You can get an extra ticket with each referral as well as 5% of all the points they earn, and you can claim them from the 'Airdrop' section by clicking on 'Invite friends…'.",
      },
      {
        question: 'When can I redeem my tokens?',
        answer:
          'You can see detailed information about $DMO and its roadmap from our web portal.',
      },
    ],
    account: [
      {
        question: 'How can I link my email address?',
        answer:
          "If you registered with Telegram and want to link a Google or Apple email, you must log in from your browser by clicking 'Launch Desktop' then 'continue with Telegram'. Once you log in, press 'Linking accounts' and select the email of your choice.",
      },
      {
        question: 'How can I link my Telegram account?',
        answer:
          "Log in from your browser and go to 'Linking accounts'. There you can select 'Continue with Telegram' and if you do not have a previous profile linked to your phone number, you can do the linking.",
      },
    ],
    other: [],
  };

  const FAQItem = ({
    question,
    answer,
    isOpen,
    onClick,
  }: {
    question: string;
    answer: string;
    isOpen: boolean;
    onClick: () => void;
  }) => {
    const [maxHeight, setMaxHeight] = useState<string | number>(0);
    const answerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (isOpen && answerRef.current) {
        setMaxHeight(answerRef.current.scrollHeight);
      } else {
        setMaxHeight(0);
      }
    }, [isOpen]);

    return (
      <div className="faq-item">
        <button className="faq-question" onClick={onClick}>
          {question}
          <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9662;</span>
        </button>
        <div
          className="faq-answer-container"
          style={{
            maxHeight: isOpen ? maxHeight : 0,
            overflow: 'hidden',
            transition: 'max-height 0.3s ease',
          }}
          ref={answerRef}
        >
          <div className="faq-answer">{answer}</div>
        </div>
      </div>
    );
  };
  return (
    <Layout
      icon={false}
      showBottomNav={false}
      showNotification={false}
      showNavMobile={false}
    >
      <div id="support">
        <div className="balance-container">
          <div className="balance-section">
            <span className="balance-label">Select a theme:</span>
          </div>

          {option === 'none' && (
            <div id="support-items">
              <div className="item" onClick={() => setOption('tasks')}>
                <span>Tasks</span>
              </div>
              <div className="item" onClick={() => setOption('stake')}>
                <span>Stake</span>
              </div>
              <div className="item" onClick={() => setOption('deposit')}>
                <span>Deposit</span>
              </div>
              <div className="item" onClick={() => setOption('withdraw')}>
                <span>Withdraw</span>
              </div>
              <div className="item" onClick={() => setOption('referrals')}>
                <span>Referrals</span>
              </div>
              <div className="item" onClick={() => setOption('airdrop')}>
                <span>Airdrop</span>
              </div>
              <div className="item" onClick={() => setOption('account')}>
                <span>Account</span>
              </div>
              <div className="item">
                <a href="mailto:support@miningoptions.net" target="_blank">
                  <span>Other</span>
                </a>
              </div>
            </div>
          )}

          <br />
          <br />
          {option !== 'none' && (
            <>
              {faqData[option].map((faq, index) => (
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openIndex === index}
                  onClick={() => handleToggle(index)}
                />
              ))}

              <center style={{ width: '40%', margin: '0 auto' }}>
                <Buttom
                  label="Back"
                  size="lg"
                  onClick={() => setOption('none')}
                />
              </center>
            </>
          )}
        </div>
        <br />
      </div>
    </Layout>
  );
}

export default App;
