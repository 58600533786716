import { useState } from 'react';
import LogoBlack from '@assets/logos/black.svg';
import { authLogin } from 'hooks/useAuth';
import './style.css';
import { useToast } from '@context/ToastContext';
import { Link } from 'react-router-dom';
const LoginForm = () => {
  const { showToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const authProiver = () => {
    setLoading(true);
    const data = {
      provider: 'email',
      provider_user_id: 1549073170,
      name: email,
      email: email,
      password: password,
    };

    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === '') {
      showToast('Email field is required.', 'error');
      setLoading(false);
      return false;
    } else if (!emailRegex.test(email)) {
      showToast('Please enter a valid email.', 'error');
      setLoading(false);
      return false;
    }

    if (password === '') {
      showToast('The password field is required.', 'error');
      setLoading(false);
      return false;
    }

    authLogin(data)
      .then((_) => {
        setLoading(false);
        window.location.href = '/';
      })
      .catch((error: any) => {
        setLoading(false);
        showToast('An error occurred', 'error');
        console.log('error', error);
      });
  };
  return (
    <div className="login-container">
      {loading && <div className="loadingAuth">laoding</div>}

      <div className="login-left">
        <div className="login-content">
          <img src={LogoBlack} alt="Logo" className="logo" />
          <h1>Sing In to your Account</h1>
          <p>Welcome back! Select method to log in:</p>
          <p>or continue with email</p>
          <form>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <button
              type="button"
              onClick={authProiver}
              value={password}
              className="login-button"
            >
              Sing Up
            </button>
            <br />
            <center>
              <Link to="/sing-in">Sing In</Link>
            </center>
          </form>
        </div>
      </div>
      <div className="login-right">
        <div className="promo-content">
          <h2>Connect with every application.</h2>
          <p>Everything you need in an easily customizable dashboard.</p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
