import { useEffect, useState } from 'react';
import Buttom from '@components/buttons';
import { InputText } from '@components/forms';
import { ReactComponent as BinanceIcon } from '@assets/icons/binance.svg';
import { useToast } from '@context/ToastContext';
import './style.css';
import { useExtraTasks } from '@hooks/useExtraTask';
import { ReactComponent as CoheteIcon } from '@assets/icons/lanzamiento-de-cohete-espacial.svg';
import { useNavigate } from 'react-router-dom';

function App() {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const {
    store,
    data,
    activeExtraTaskBoost,
    hasBoostBinance,
    hasBoostYoutube,
    usedBoostBinance,
    usedBoostYoutube,
  } = useExtraTasks();
  const [postLinkBinance, setPostLinkBinance] = useState<string>('');
  const [postLinkYoutube, setPostLinkYoutube] = useState<string>('');

  const [statusBinance, setStatusBinance] = useState<
    'Pending' | 'Done' | 'Refused' | 'none'
  >('none');

  const [statusYoutube, setStatusYoutube] = useState<
    'Pending' | 'Done' | 'Refused' | 'none'
  >('none');

  useEffect(() => {
    if (data.length > 0) {
      data.forEach((item) => {
        switch (item.provider) {
          case 'Binance':
            setStatusBinance(item.status);
            setPostLinkBinance(item.post_link);
            break;
          case 'Youtube':
            setStatusYoutube(item.status);
            setPostLinkYoutube(item.post_link);
            break;
          default:
            break;
        }
      });
    }
  }, [data]);

  const storeLinkPost = (provider: 'Binance' | 'Youtube') => {
    const postLinks = {
      Binance: postLinkBinance,
      Youtube: postLinkYoutube,
    };

    const errorMessages = {
      Binance: 'Post Link Binance field is required.',
      Youtube: 'Post Link Youtube field is required.',
    };

    if (postLinks[provider] === '') {
      showToast(errorMessages[provider], 'error');
      return;
    }

    const data = {
      provider,
      post_link: postLinks[provider],
    };
    store(data).then(() => {
      if (provider === 'Binance') {
        setStatusBinance('Pending');
      }

      if (provider === 'Youtube') {
        setStatusYoutube('Pending');
      }
      alert('successful operation');
    });
  };

  const activeBoost = (type: 'Binance' | 'Youtube') => {
    activeExtraTaskBoost(type)
      .then(() => {
        //setCompletedTasks(false);
        alert('successful operation');
      })
      .catch(() => {
        alert('An error occurred');
      });
  };

  return (
    <div className="task-list">
      <div className="extra-task-list">
        <p>
          Post about Mining Options on Binance Square and tag us to get a 10%
          boost to the estimated profits of a selected stake
        </p>

        {statusBinance === 'Refused' && (
          <center style={{ margin: '0 auto' }}>
            <p style={{ textAlign: 'center', color: 'red' }}>{statusBinance}</p>
          </center>
        )}

        <div className="extra-task-item">
          <div className="extra-task-content-input">
            <div className="content-icon-extra-task">
              <BinanceIcon />
            </div>
            <InputText
              value={postLinkBinance}
              disabled={
                statusBinance === 'Pending' || statusBinance === 'Done'
                  ? true
                  : false
              }
              placeholder="Binance post link"
              size="xs"
              onChange={setPostLinkBinance}
            />
          </div>

          {statusBinance === 'none' || statusBinance === 'Refused' ? (
            <>
              <Buttom
                icon="fas fa-check"
                size="md"
                onClick={() => {
                  storeLinkPost('Binance');
                }}
              />
            </>
          ) : (
            <Buttom disabled={true} label={statusBinance} size="md" />
          )}
        </div>

        {!usedBoostBinance ? (
          <div className="btn-boost-stake">
            <Buttom
              label="Boost Stake"
              size="lg"
              disabled={statusBinance !== 'Done' ? true : false}
              onClick={() => {
                if (!usedBoostBinance) {
                  navigate('/stake-summary', {
                    state: { boost_stake_id: hasBoostBinance.id },
                  });
                }
              }}
            />
          </div>
        ) : (
          <div className="btn-boost-stake">
            <a
              className={`activateReward`}
              onClick={() => {
                alert('Boost Stake activated');
              }}
            >
              <CoheteIcon height={20} />
            </a>
          </div>
        )}

        <br />

        <p>
          Post about Mining Options on Youtube to get a 10% boost to the
          estimated profits of a selected stake
        </p>

        {statusYoutube === 'Refused' && (
          <center style={{ margin: '0 auto' }}>
            <p style={{ textAlign: 'center', color: 'red' }}>{statusYoutube}</p>
          </center>
        )}

        <div className="extra-task-item">
          <div className="extra-task-content-input">
            <div className="content-icon-extra-task">
              <i
                className={`task-icon fab fa-youtube`}
                style={{ marginLeft: '-4px' }}
              ></i>
            </div>
            <InputText
              value={postLinkYoutube}
              disabled={
                statusYoutube === 'Pending' || statusYoutube === 'Done'
                  ? true
                  : false
              }
              placeholder="Youtube post link"
              size="xs"
              onChange={setPostLinkYoutube}
            />
          </div>

          {statusYoutube === 'none' || statusYoutube === 'Refused' ? (
            <Buttom
              icon="fas fa-check"
              size="md"
              onClick={() => {
                storeLinkPost('Youtube');
              }}
            />
          ) : (
            <Buttom disabled={true} label={statusYoutube} size="md" />
          )}
        </div>

        {!usedBoostYoutube ? (
          <div className="btn-boost-stake">
            <Buttom
              label="Boost Stake"
              size="lg"
              disabled={statusYoutube !== 'Done' ? true : false}
              onClick={() => {
                if (!usedBoostYoutube) {
                  navigate('/stake-summary', {
                    state: { boost_stake_id: hasBoostYoutube.id },
                  });
                }
              }}
            />
          </div>
        ) : (
          <div className="btn-boost-stake">
            <a
              className={`activateReward`}
              onClick={() => {
                alert('Boost Stake activated');
              }}
            >
              <CoheteIcon height={20} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
