import Layout from '@components/layout';
import './style.css';
import Buttom from '@components/buttons';
import { InputText } from '@components/forms';
import Divider from '@components/divider';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';
import { useEffect, useState } from 'react';
import { useReferrals } from '@hooks/useReferrals';

function App() {
  const REACT_APP_URI = process.env.REACT_APP_URI;

  const { user, miniapp } = useSelector(
    (state: AppStateType) => state.initLoad,
  );

  const { data, balance, claimComission, claimComissionAll } = useReferrals();
  const [userCode] = useState<string>(
    miniapp
      ? `t.me/Miningoptionsbot/?startapp=${user.user_code}`
      : `${REACT_APP_URI}/?refercode=${user.user_code}`,
  );

  const [networkBalance, setNetworkBalance] = useState(0);

  useEffect(() => {
    const amount = data.reduce((total: number, item: any) => {
      const commission =
        item.pending_commissions.length > 0
          ? item.pending_commissions[0].total_comission
          : 0.0;

      return total + commission;
    }, 0);

    setNetworkBalance(amount);
  }, [data]);

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(userCode)
        .then(() => {
          alert('Link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Error al copiar el enlace: ', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = userCode;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      alert('Link copied to clipboard!');
    }
  };

  const claim = (user_id: number) => {
    const isConfirmed = window.confirm('Are you sure to perform this action?');

    if (isConfirmed) {
      const data = {
        user_id,
      };
      claimComission(data);
    } else {
      console.log('Cancelado');
    }
  };

  const claimAll = () => {
    const isConfirmed = window.confirm('Are you sure to perform this action?');

    if (isConfirmed) {
      claimComissionAll();
    } else {
      console.log('Cancelado');
    }
  };
  return (
    <Layout icon={false} showBottomNav={true} showGoBack={false}>
      <div id="frens">
        <div className="balance-container">
          <div className="balance-section">
            <span className="balance-label">Total balance</span>
            <div className="balance-amount">{balance} USDT</div>
          </div>
        </div>

        <div style={{ width: '100%', margin: '0 auto' }}>
          <h3>Number of referrals: {data.length}</h3>
        </div>

        <div className="user-list-container">
          {data.map((user: any) => {
            const amount =
              user.pending_commissions.length > 0
                ? user.pending_commissions[0].total_comission
                : 0.0;
            return (
              <div key={user.id} className="user-row">
                <span className="user-name">{user.name}</span>
                <span className="user-balance">{amount} USDT</span>

                <Buttom
                  size="xs"
                  label="Claim"
                  onClick={() => claim(user.id)}
                  disabled={user.pending_commissions.length > 0 ? false : true}
                />
              </div>
            );
          })}
        </div>

        <h3>Unclaimed balance: {networkBalance} USDT</h3>

        <br />

        <div className="btn-claim">
          <Buttom
            size="lg"
            label="CLAIM ALL"
            onClick={claimAll}
            disabled={data.length > 0 && networkBalance > 0 ? false : true}
          />
        </div>
        <br />

        <div style={{ width: '100%', margin: '0 auto' }}>
          <p className="text-small">
            Get 8% of the staking rewards from your referrals
          </p>
        </div>
        <div className="btn-copy" onClick={handleCopy}>
          <h3>Referral Link</h3>
        </div>
      </div>
    </Layout>
  );
}

export default App;
