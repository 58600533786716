import Layout from '@components/layout';
import './style.css';
import { InputText } from '@components/forms';
import Buttom from '@components/buttons';
import { useEffect, useState } from 'react';
import { useWallet } from '@hooks/useWallet';
import { motion } from 'framer-motion';
import { useToast } from '@context/ToastContext';
import { useStake } from '@hooks/useStake';
import { useReferrals } from '@hooks/useReferrals';

type typeOptions = 'Wallet' | 'Stake' | 'Referrals';

function App() {
  const { data: balanceWallet, transfer, refresh: refreshWallet } = useWallet();
  const { data: balanceStake, refresh: refreshStake } = useStake();
  const { balance: balanceRefferals, getBalance: getBalanceRefferals } =
    useReferrals();

  const { showToast } = useToast();
  const [amount, setAmount] = useState<number | string>('');
  const [balance, setBalance] = useState<any>('0');
  const [isDropdownOpenFrom, setIsDropdownOpenFrom] = useState(false);
  const [isDropdownOpenTo, setIsDropdownOpenTo] = useState(false);
  const [selectedOption, setSelectedOption] = useState<typeOptions>('Wallet');
  const [selectedOptionTo, setSelectedOptionTo] =
    useState<typeOptions>('Stake');

  const toggleDropdown = () => {
    setIsDropdownOpenFrom(!isDropdownOpenFrom);
  };

  const handleOptionSelect = (option: typeOptions) => {
    if (option !== selectedOptionTo) {
      setSelectedOption(option);
      setIsDropdownOpenFrom(false);
    }
  };

  const toggleDropdownTo = () => {
    setIsDropdownOpenTo(!isDropdownOpenTo);
  };

  const handleOptionSelectTo = (option: typeOptions) => {
    if (option !== selectedOption) {
      setSelectedOptionTo(option);
      setIsDropdownOpenTo(false);
    }
  };

  useEffect(() => {
    setBalance(
      selectedOption === 'Wallet'
        ? balanceWallet
          ? balanceWallet.balance
          : 0
        : selectedOption === 'Stake'
        ? balanceStake
          ? balanceStake?.balance
          : 0
        : selectedOption === 'Referrals'
        ? balanceRefferals
        : 0,
    );
  }, [balanceWallet, balanceStake, balanceRefferals, selectedOption]);

  const handleExchange = () => {
    setSelectedOption(selectedOptionTo);
    setSelectedOptionTo(selectedOption);
  };

  const setMax = () => {
    setAmount(balance);
  };

  const onTransfer = () => {
    transfer({
      amount,
      from: selectedOption,
      to: selectedOptionTo,
    })
      .then((response) => {
        if (response.ok) {
          showToast('successful operation', 'success');
        }

        setAmount('');
        refreshWallet();
        refreshStake();
        getBalanceRefferals();
      })
      .catch(() => {
        showToast('An error occurred', 'error');
      });
  };

  const handleInputChange = (inputValue: any) => {
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    if (inputValue.split('.').length > 2) {
      return;
    }

    setAmount(inputValue);
  };

  return (
    <Layout
      title="TRANSFER"
      icon={false}
      showBottomNav={true}
      showNavMobile={false}
      showNotification={false}
    >
      <div id="transfer">
        <div className="content-transaction">
          <div className="content-options-transfer">
            <div className="options-transfer">
              <div className="options-transfer-item" onClick={toggleDropdown}>
                <span>{selectedOption}</span>
                <i className="fas fa-chevron-down"></i>
              </div>

              {isDropdownOpenFrom && (
                <div className="dropdown-menu">
                  {selectedOption !== 'Wallet' &&
                    selectedOptionTo !== 'Wallet' && (
                      <div
                        onClick={() => handleOptionSelect('Wallet')}
                        className="dropdown-item"
                      >
                        Wallet
                      </div>
                    )}

                  {selectedOption !== 'Stake' &&
                    selectedOptionTo !== 'Stake' && (
                      <div
                        onClick={() => handleOptionSelect('Stake')}
                        className="dropdown-item"
                      >
                        Stake
                      </div>
                    )}
                  {selectedOption !== 'Referrals' &&
                    selectedOptionTo !== 'Referrals' && (
                      <div
                        onClick={() => handleOptionSelect('Referrals')}
                        className="dropdown-item"
                      >
                        Referrals
                      </div>
                    )}
                </div>
              )}

              <div className="options-transfer-item" onClick={toggleDropdownTo}>
                <span>{selectedOptionTo}</span>
                <i className="fas fa-chevron-down"></i>
              </div>

              {isDropdownOpenTo && (
                <div className="dropdown-menu">
                  {selectedOptionTo !== 'Wallet' &&
                    selectedOption !== 'Wallet' && (
                      <div
                        onClick={() => handleOptionSelectTo('Wallet')}
                        className="dropdown-item"
                      >
                        Wallet
                      </div>
                    )}

                  {selectedOptionTo !== 'Stake' &&
                    selectedOption !== 'Stake' && (
                      <div
                        onClick={() => handleOptionSelectTo('Stake')}
                        className="dropdown-item"
                      >
                        Stake
                      </div>
                    )}
                  {selectedOptionTo !== 'Referrals' &&
                    selectedOption !== 'Referrals' && (
                      <div
                        onClick={() => handleOptionSelectTo('Referrals')}
                        className="dropdown-item"
                      >
                        Referrals
                      </div>
                    )}
                </div>
              )}
            </div>

            <div onClick={handleExchange} className="exchange-button">
              <i className="fas fa-exchange-alt"></i>{' '}
            </div>
          </div>
        </div>
        <br />
        <div className="transaction-form">
          <div className="input-group">
            <InputText
              placeholder="Minimum 1.00 USDT"
              size="md"
              label="Amount"
              value={amount}
              action="MAX"
              onChange={handleInputChange}
              onAction={setMax}
            />
          </div>
          <div className="details">
            <span className="content-available-stake">
              <span>
                Available:{' '}
                <b>
                  {balance}
                  USDT
                </b>
              </span>
            </span>
          </div>

          <center>
            <Buttom label="CONFIRM" size="lg" onClick={onTransfer} />
          </center>
        </div>
      </div>
    </Layout>
  );
}

export default App;
