import Layout from '@components/layout';
import './style.css';
import { InputText } from '@components/forms';
import Buttom from '@components/buttons';
import Tabs from '@components/tabs';
import SwipeableComponent from '@components/swipeable';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStake } from '@hooks/useStake';
import { formatDecimal } from 'helpers/formatDecimal';

interface StakeInfoItem {
  label: string;
  value: string;
  icon: string;
}

interface StakeInfoProps {
  items: StakeInfoItem[];
}

function App() {
  const contracts = [
    { day: 1, value: '1.6' },
    { day: 5, value: '1.9' },
    { day: 10, value: '2.2' },
    { day: 30, value: '2.5' },
    { day: 90, value: '2.9' },
    { day: 180, value: '3.3' },
  ];

  const {
    data,
    refresh,
    store,
    stake,
    getStake,
    getOperationLimit,
    operationLimit,
    loadLimit,
  } = useStake();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeSwipeView, setActiveSwipeView] = useState<number>(0);

  const [amount, setAmount] = useState<number | string>('');
  const [stakeAmount, setStakeAmount] = useState<number>(0);
  const [activeContract, setActiveContract] = useState<number>(0);
  const [openOperationLimit, setOpenOperationLimit] = useState<boolean>(false);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timeLeftOperationLimit, setTimeLeftOperationLimit] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    getStake(contracts[activeContract]);
    getOperationLimit(contracts[activeContract]);
  }, [activeContract]);

  useEffect(() => {
    if ((amount as number) > 0) {
      setStakeAmount((amount as number) - 0.2);
    }
  }, [amount]);

  useEffect(() => {
    setAmount('');
    setStakeAmount(0);
  }, [activeContract]);

  const handleItemClick = (index: number) => {
    setActiveContract(index);
  };

  const handleInputChange = (inputValue: any) => {
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    if (inputValue.split('.').length > 2) {
      return;
    }

    setAmount(formatDecimal(inputValue));
  };

  const setMax = () => {
    setAmount(data ? data.balance : 0);
  };

  const navigate = useNavigate();

  const scrollContainerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (!scrollContainer) return;
      //@ts-ignore
      const scrollPosition = scrollContainer.scrollLeft;

      const maxScrollLeft = //@ts-ignore
        scrollContainer.scrollWidth - scrollContainer.clientWidth;

      const scrollPercentage = (scrollPosition / maxScrollLeft) * 100;

      if (scrollPercentage >= 50) {
        setActiveIndex(1);
      } else {
        setActiveIndex(0);
      }
    };

    //@ts-ignore
    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      if (scrollContainer) {
        //@ts-ignore
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollContainerRef]);

  const StakeSummary: React.FC<StakeInfoProps> = ({ items }) => {
    return (
      <div className="stake-info">
        {items.map((item, index) => (
          <div className="stake-info-item" key={index}>
            <div className="stake-info-label">{item.label}</div>
            <div className="stake-info-value">{item.value}</div>
          </div>
        ))}
      </div>
    );
  };

  function calculateTotalWithCompoundInterest(
    stakeAmount: any,
    dailyPercentage: any,
    contractDays: any,
  ) {
    const compoundCycleDays = 30;
    const dailyRate = dailyPercentage / 100; // Tasa diaria en formato decimal

    const fullCycles = Math.floor(contractDays / compoundCycleDays);

    for (let i = 0; i < fullCycles; i++) {
      stakeAmount *= Math.pow(1 + dailyRate * compoundCycleDays, 1);
    }

    // Calcular los días restantes fuera de los ciclos de 30 días
    const remainingDays = contractDays % compoundCycleDays;

    // Aplicar el interés simple a los días restantes
    if (remainingDays > 0) {
      stakeAmount *= Math.pow(1 + dailyRate, remainingDays); // Interés simple en días restantes
    }

    return stakeAmount;
  }

  const valuewithCompoundInterest = calculateTotalWithCompoundInterest(
    stakeAmount,
    contracts[activeContract].value,
    contracts[activeContract].day,
  );

  const formattedvaluewithCompoundInterest = (
    valuewithCompoundInterest - stakeAmount
  )
    .toString()
    .match(/^-?\d+(?:\.\d{0,2})?/);

  //@ts-ignore
  const daily_reward = (stakeAmount / 100) * contracts[activeContract].value;

  const estimated_reward = daily_reward * contracts[activeContract].day;

  const formattedRewardMatch = daily_reward
    .toString()
    .match(/^-?\d+(?:\.\d{0,2})?/);

  const formattedTotalRewardMatch = estimated_reward
    .toString()
    .match(/^-?\d+(?:\.\d{0,2})?/);

  const itemsSummary = [
    {
      label: 'Stake Amount:',
      value: `${stakeAmount} USDT`,
      icon: 'fas fa-cubes',
    },
    {
      label: 'Daily Reward:',
      value: `${formattedRewardMatch ? formattedRewardMatch[0] : '0.00'} USDT`,
      icon: 'fas fa-coins',
    },
    {
      label: 'Estimated Reward:',
      value: `${
        contracts[activeContract].day > 30
          ? formattedvaluewithCompoundInterest
            ? formattedvaluewithCompoundInterest[0]
            : '0.00'
          : formattedTotalRewardMatch
          ? formattedTotalRewardMatch[0]
          : '0.00'
      } USDT`,
      icon: 'fas fa-clock',
    },
  ];

  const tabs = [
    {
      title: 'Details',
    },
  ];

  const handleIndexChange = (index: number) => {
    setActiveTab(index);
    setActiveSwipeView(index);
  };

  const handleIndexChangeTab = (index: number) => {
    setActiveSwipeView(index);
  };

  const components = [<StakeSummary items={itemsSummary} />];

  useEffect(() => {
    if (stake) {
      /*setActiveContract(
        contracts.findIndex((contract) => contract.day === stake.contract_day),
      );*/
      setTimeout(() => {
        setStakeAmount(parseFloat(stake.amount));
      }, 1000);
    }

    const interval = setInterval(() => {
      if (stake) {
        const currentTime = new Date();

        const createdDate = new Date(stake.created_at);
        const contractDuration = (stake.contract_day + 1) * 24 * 60 * 60 * 1000;
        const endTime = createdDate.getTime() + contractDuration;
        const remainingTime = endTime - currentTime.getTime();

        if (remainingTime > 0) {
          const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
          );
          const minutes = Math.floor(
            (remainingTime % (1000 * 60 * 60)) / (1000 * 60),
          );
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
          setTimeLeft({ days, hours, minutes, seconds });
        } else {
          // Time's up
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [stake]);

  useEffect(() => {
    if (operationLimit) {
      const currentTime = new Date();

      const createdDate = new Date(operationLimit.created_at);
      const contractDuration = (30 + 1) * 24 * 60 * 60 * 1000;
      const endTime = createdDate.getTime() + contractDuration;
      const remainingTime = endTime - currentTime.getTime();

      if (remainingTime > 0) {
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60),
        );
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        setTimeLeftOperationLimit({ days, hours, minutes, seconds });
      } else {
        // Time's up
        setTimeLeftOperationLimit({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }
    } else {
      setTimeLeftOperationLimit({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    }
  }, [operationLimit]);

  const displayTime =
    timeLeft.days > 0
      ? `${timeLeft.days} day${timeLeft.days > 1 ? 's' : ''}`
      : `${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`;

  const displayTimeOperationLimit =
    timeLeftOperationLimit.days > 0
      ? `${timeLeftOperationLimit.days} day${
          timeLeftOperationLimit.days > 1 ? 's' : ''
        }`
      : `${timeLeftOperationLimit.hours}h ${timeLeftOperationLimit.minutes}m ${timeLeftOperationLimit.seconds}s`;

  const confirmStake = () => {
    if (amount === '') {
      alert('Amount is required');
    } else {
      const isConfirmed = window.confirm(
        `Are you sure you will enter into the contract for an amount of ${amount}?`,
      );

      if (isConfirmed) {
        const data = {
          amount,
          contract: contracts[activeContract],
        };

        store(data).then(() => {
          refresh();
          getStake(contracts[activeContract]);
        });
      } else {
        console.log('Cancelado');
      }
    }
  };

  return (
    <Layout showBottomNav={true} showGoBack={false}>
      {openOperationLimit && (
        <div
          id="modal-operation-limit"
          onClick={() => setOpenOperationLimit(false)}
        >
          <div className="content-operation-limit">
            <h4>Operation limit reset time</h4>
            <h4>{displayTimeOperationLimit}</h4>
          </div>
        </div>
      )}

      <div id="stake">
        <div id="layout-title" className="title-stake">
          <img src={require('./assets/icon.png')} alt="" />
          <h3>USDT CONTRACTS</h3>
        </div>
        <div className="scroll-container" ref={scrollContainerRef}>
          {contracts.map((item, index) => (
            <div
              key={index}
              className={`scroll-item ${
                activeContract === index ? 'active' : ''
              }`}
              onClick={() => handleItemClick(index)}
            >
              <div className="label-day">{item.day} d</div>
              <div>{item.value}%</div>
            </div>
          ))}
        </div>

        <div className="scroll-horizontal-dots">
          <div className={activeIndex === 0 ? 'active' : ''}> </div>
          <div className={activeIndex === 1 ? 'active' : ''}> </div>
        </div>

        <div className="transaction-form">
          <div className="input-group">
            <InputText
              placeholder="Minimum 1.00 USDT"
              size="md"
              label="Amount"
              value={amount}
              disabled={stake ? true : false}
              onChange={handleInputChange}
              action="MAX"
              onAction={setMax}
            />
          </div>
          <div className="details">
            <span className="content-available-stake">
              <span className={'content-available-stake-item'}>
                <div> Available: &nbsp;</div>
                <div>
                  <b>{data ? data.balance : '0.0'} USDT</b>
                </div>
              </span>
              <div
                className="icon-transfer"
                onClick={() => navigate('/transfer')}
              >
                <i className="fas fa-exchange-alt"></i>
              </div>
            </span>

            <span className={'content-available-stake-item'}>
              <div>Transaction Fee: &nbsp;</div>
              <div>
                <b>0.20 USDT</b>
              </div>
            </span>
          </div>
          <div className="operation-limit">
            <span>
              Operation Limit:
              <b>
                {amount != '' ? amount : '0.00'}/
                {operationLimit ? operationLimit.limit_amout : '0.00'} USDT
              </b>
            </span>

            {!loadLimit && (
              <div
                className="icon-transfer"
                onClick={() =>
                  alert(
                    `Operation limit reset time\n ${displayTimeOperationLimit}`,
                  )
                }
              >
                <i className="far fa-question-circle"></i>
              </div>
            )}
          </div>
          <div className="btn-stake-timer">
            <Buttom
              label="CONFIRM"
              size="lg"
              disabled={stake ? true : false}
              onClick={confirmStake}
            />
          </div>
        </div>
        <br />
        <br />
        <div id="content-tabs-stake">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onIndexChange={handleIndexChangeTab}
          />
        </div>

        <SwipeableComponent
          currentItem={activeSwipeView}
          components={components}
          onIndexChange={handleIndexChange}
        />
        <br />

        <div className={`btn-stake-timer ${stake && 'actived'}`}>
          <Buttom
            label={stake ? displayTime : '0:00'}
            size="lg"
            iconLeft={`fas ${stake ? 'fa-lock' : 'fa-lock-open'}`}
          />
        </div>
      </div>
    </Layout>
  );
}

export default App;
